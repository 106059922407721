var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-link-line p-link-line--error c-section" },
    [
      _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
        _vm._v("LINE連携に失敗しました"),
      ]),
      _c("p", [
        _vm._v("アプリから再度LINE連携を行い、認証を許可してください。"),
      ]),
      _c(
        "div",
        { staticClass: "p-link-line__actions" },
        [
          _c(
            "Button",
            {
              attrs: {
                href: _vm.$customUrlScheme.page("standalone", "url=/link/line"),
                "style-type": "tertiary",
                fluid: "",
                flat: "",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$wordings.accountLine) + "連携画面へ戻る "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }